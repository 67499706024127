<template>
  <v-dialog v-model="dialog" width="550" height="480" persistent>
    <v-card class="pa-0" style="border: 0px !important">
      <v-card-title class="px-4 py-4">
        <div class="dialog-title">{{ title }}</div>
        <v-btn class="dialog-btn-close" id="btnCloseX" icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="dialog-body">
        <div class="dialog-container mb-4" v-for="(h, index) in history" :key="index">
          <div class="row my-0 mx-0">
            <div class="col-7 px-0 py-0">
              Atualização realizada por <br />
              <strong>{{ h.user }}</strong>
            </div>
            <div class="col-5 px-0 py-0 text-right">
              Data/hora <br />
              <strong>{{ h.date }}</strong>
            </div>
          </div>
          <v-divider class="mt-4 mb-4"></v-divider>
          <div class="row">
            <div class="col-12 px-4 pt-0 pb-4">
              <v-subheader class="pa-0" style="max-height: 24px">Informação alterada:</v-subheader>
              <div v-for="(difference, index) in h.differences" :key="index">
                <p class="pa-0">
                  {{ difference.label }}: De <strong> {{ difference.before }} </strong> para
                  <strong>{{ difference.after }}</strong>
                </p>
              </div>
            </div>
          </div>
          <v-divider class="mt-0 mb-4"></v-divider>
          <div class="row my-0 mx-0">
            <div class="col-12 px-0 py-0">Observação / Embasamento legal:</div>
            <div class="col-12 px-0 py-0">
              <strong>{{ h.notes }}</strong>
            </div>
          </div>
        </div>

        <div class="dialog-msg-end">
          <strong>Você visualizou toda o histórico de alteração desta natureza.</strong>
        </div>
      </v-card-text>
      <v-card-actions class="dialog-actions">
        <v-spacer />
        <v-btn class="btn-ag-primary" @click="dialog = false" id="btnClose"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModalIncidenceNatureHistory',
  data() {
    return {
      dialog: false,
    };
  },
  created() {
    this.$on('open', this.open);
  },
  props: {
    title: {
      type: String,
      require: true,
      default: '',
    },
    history: {
      type: Array,
      require: true,
      default: [],
    },
  },
  methods: {
    open() {
      this.dialog = true;
    },

    start_and_end(str, lengthStr) {
      if (str != undefined) {
        if (str.length > lengthStr) {
          return str.substr(0, lengthStr) + '...';
        }
        return str;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-title {
  width: 453px;
  height: 30px;
  color: #182026;
  font-size: 20px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.2px;
  word-wrap: break-word;
}
.dialog-btn-close {
  width: 32px;
  height: 32px;
  padding: 4px;
  margin-left: 29px;
}
.dialog-btn-close i {
  width: 24px;
  height: 24px;
  margin-right: 0px !important;
}
.dialog-body {
  background-color: #e7e7fa;
  padding: 16px !important;
  min-height: 336px;
  max-height: 336px;
  overflow-y: scroll;
}
.dialog-container {
  background-color: #ffffff;
  padding: 16px;
  border-radius: 4px;
}
.dialog-actions {
  border-top: 1px #e7e7fa solid;
  padding: 16px !important;
}
.dialog-msg-end {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  color: #2a1c4f !important;
}
</style>
