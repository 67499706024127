var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "550", height: "480", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-0", staticStyle: { border: "0px !important" } },
        [
          _c(
            "v-card-title",
            { staticClass: "px-4 py-4" },
            [
              _c("div", { staticClass: "dialog-title" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "dialog-btn-close",
                  attrs: { id: "btnCloseX", icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "dialog-body" },
            [
              _vm._l(_vm.history, function (h, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "dialog-container mb-4" },
                  [
                    _c("div", { staticClass: "row my-0 mx-0" }, [
                      _c("div", { staticClass: "col-7 px-0 py-0" }, [
                        _vm._v(" Atualização realizada por "),
                        _c("br"),
                        _c("strong", [_vm._v(_vm._s(h.user))]),
                      ]),
                      _c("div", { staticClass: "col-5 px-0 py-0 text-right" }, [
                        _vm._v(" Data/hora "),
                        _c("br"),
                        _c("strong", [_vm._v(_vm._s(h.date))]),
                      ]),
                    ]),
                    _c("v-divider", { staticClass: "mt-4 mb-4" }),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 px-4 pt-0 pb-4" },
                        [
                          _c(
                            "v-subheader",
                            {
                              staticClass: "pa-0",
                              staticStyle: { "max-height": "24px" },
                            },
                            [_vm._v("Informação alterada:")]
                          ),
                          _vm._l(h.differences, function (difference, index) {
                            return _c("div", { key: index }, [
                              _c("p", { staticClass: "pa-0" }, [
                                _vm._v(
                                  " " + _vm._s(difference.label) + ": De "
                                ),
                                _c("strong", [
                                  _vm._v(" " + _vm._s(difference.before) + " "),
                                ]),
                                _vm._v(" para "),
                                _c("strong", [
                                  _vm._v(_vm._s(difference.after)),
                                ]),
                              ]),
                            ])
                          }),
                        ],
                        2
                      ),
                    ]),
                    _c("v-divider", { staticClass: "mt-0 mb-4" }),
                    _c("div", { staticClass: "row my-0 mx-0" }, [
                      _c("div", { staticClass: "col-12 px-0 py-0" }, [
                        _vm._v("Observação / Embasamento legal:"),
                      ]),
                      _c("div", { staticClass: "col-12 px-0 py-0" }, [
                        _c("strong", [_vm._v(_vm._s(h.notes))]),
                      ]),
                    ]),
                  ],
                  1
                )
              }),
              _c("div", { staticClass: "dialog-msg-end" }, [
                _c("strong", [
                  _vm._v(
                    "Você visualizou toda o histórico de alteração desta natureza."
                  ),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "v-card-actions",
            { staticClass: "dialog-actions" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "btn-ag-primary",
                  attrs: { id: "btnClose" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(" Fechar ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }